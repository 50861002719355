import type {Maybe} from '../types'
import {defineStore} from 'pinia'

interface Toast {
  title: string
  messages: string[]
  isError: boolean
}

type State = {
  currentToast: Maybe<Toast>
}

let timeoutId = -1

export const useToastStore = defineStore('toast', {
  state(): State {
    return {
      currentToast: undefined,
    }
  },
  actions: {
    __addToast(title: string, messages: string[], isError = false) {
      this.currentToast = null
      clearTimeout(timeoutId)

      setTimeout(() => {
        this.currentToast = {title, messages, isError}
        timeoutId = window.setTimeout(this.clearToast, 8000)
      }, 100)
    },
    addSuccessToast(title: string, ...messages: string[]) {
      this.__addToast(title, messages)
    },
    addErrorToast(title: string, ...messages: string[]) {
      this.__addToast(title, messages, true)
    },
    clearToast() {
      this.currentToast = null
    },
  },
})
