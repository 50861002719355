<script setup lang="ts">
import {computed} from 'vue'
import {storeToRefs} from 'pinia'
import {useToastStore} from '../stores/toast'
import RBButton from '../components/Button.vue'

declare global {
  var HubSpotConversations: {
    widget: {
      open: () => void
    }
  }
}

const toastStore = useToastStore()
const {currentToast} = storeToRefs(toastStore)

const classes = computed(() => ({
  'border-t-[10px] border-olive': true,
  'border-red': currentToast.value && currentToast.value.isError,
}))

function report() {
  window.HubSpotConversations.widget.open()
}
</script>

<template>
  <div class="fixed w-[75%] left-[50%] translate-x-[-50%] bottom-0 overflow-y-hidden z-toast">
    <transition
      enter-active-class="duration-500 ease-in-out"
      enter-from-class="translate-y-full scale-y-25 opacity-0"
      enter-to-class="translate-y-0 scale-y-100 opacity-100"
      leave-active-class="duration-500 ease-in-out"
      leave-from-class="translate-y-0 scale-y-100 opacity-100"
      leave-to-class="translate-y-full scale-y-25 opacity-0"
    >
      <div
        v-if="!!currentToast"
        :class="classes"
        class="relative max-w-md max-h-fit mx-auto shadow-md mb-6 p-6 bg-branddark rounded-[4px]"
      >
        <RBButton v-if="currentToast.isError" variant="warning-text" class="absolute right-0 top-0 mr-6 mt-6" @click="report">
          <i class="fa-solid fa-location-arrow"></i> {{ $t('report') }}
        </RBButton>
        <h1 class="text-white">{{ currentToast.title }}</h1>
        <p class="text-white" v-for="message in currentToast.messages" :key="message">{{ message }}</p>
      </div>
    </transition>
  </div>
</template>
